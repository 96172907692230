.lvd-main {
  width: 5px;
}

.lvd-rating-icon {
  display: inline-block;
  margin-right: 15px;
}

.lvd-progress-button {
  position: absolute;
  top: 50%;
  left: "50%";
  margin-top: -12px;
  margin-left: -12px;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.lvd-main-progress {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.lvd-nav-progress {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  height: 200px;
}
.lvd-datatable * {
  outline: none;
}

/* .lvd-datatable {
  height: -moz-calc(100vh - 400px);
  height: -webkit-calc(100vh - 400px);
  height: calc(100vh - 400px);
} */

.lvd-datatable .rt-thead {
  box-shadow: none !important;
  color: #3f526b !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;

  font-size: 13px !important;
  border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
}

.lvd-datatable .rt-th {
  opacity: 0.6;
  text-align: left !important;
  padding: 16px 8px !important;
  font-weight: 500 !important;
}

.lvd-datatable .rt-tr-group {
  border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
}

.lvd-datatable .rt-td {
  color: #3f526b !important;
  line-height: 48px !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  padding: 8px !important;
}

.lvd-datatable .-pagination {
  box-shadow: none !important;
}

.lvd-required {
  display: inline-block;
  background: #fff;
  border: 1px solid #81c534;
  color: #81c534;
  height: 16px;
  line-height: 16px;
  padding: 0 5px;
  border-radius: 3px;
  font-size: 10px;
  margin-left: 10px;
  position: relative;
}

.lvd-logo {
  margin-top: 20px;
  max-width: 100px;
  height: auto;
}

.lvd-nav:hover,
.lvd-nav-sub:hover {
  background-color: #f1f2f4 !important;
}
.lvd-nav-sub span {
  font-weight: 300;
  font-size: 15px !important;
}

.lvd-bg-main {
  background-color: #f1f2f4;
  padding: 30px;
}
.lvd-menu-left svg {
  margin-right: 0;
  color: #e1e5e9;
}
.lvd-breadcrumbs {
  font-weight: 300;
  font-size: 14px;
  color: #3f526c;
}
.lvd-breadcrumbs span {
  display: inline-block;
  margin-right: 6px;
}
.lvd-breadcrumbs a {
  display: inline-block;
  margin-right: 6px;
  color: #3f526c;
  text-decoration: none;
}

.lvd-breadcrumbs a:hover {
  text-decoration: underline;
}

.lvd-drawer-icon {
  left: -16px;
}
.lvd-account-icon {
  right: -12px;
}

.lvd-normal-text-color {
  color: #3f526c;
}

.lvd-control-buttons {
  margin-bottom: 20px;
}

.lvd-control-buttons button {
  margin-right: 16px;
  margin-bottom: 10px;
}

.lvd-spacer10 {
  height: 20px;
}
.lvd-spacer20 {
  height: 20px;
}
.lvd-spacer30 {
  height: 30px;
}
.lvd-spacer40 {
  height: 40px;
}

.lvd-button-green-text {
  background-color: #ffffff !important;
  color: #81c534 !important;
}
.lvd-button-green-text svg {
  margin-right: 6px;
}

.lvd-button-padded-icon svg {
  margin-right: 6px;
}

.lvd-button-green-text-right {
  background-color: #ffffff !important;
  color: #81c534 !important;
}

.lvd-button-green-text-right svg {
  margin-left: 6px;
}

.lvd-button-close-modal {
  position: absolute !important;
  top: 12px !important;
  right: 20px !important;
}

.lvd-input-reset {
  position: absolute;
  right: -10px;
  top: 0;
}
.lvd-input-reset svg {
  font-size: 16px;
}

.lvd-footer {
  text-align: center;
  font-size: 13px;
  font-weight: 300;
  padding-top: 60px;
  padding-bottom: 30px;
}
.lvd-footer img {
  width: 100px;
  height: auto;
  margin-bottom: 20px;
}

.lvd-footer span {
  display: block;
  margin: 0 auto;
}

.lvd-qb-select {
  background-color: red;
}

.lvd-options-container .MuiPaper-elevation8-27 {
  box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.03),
    0px 1px 2px 0px rgba(0, 0, 0, 0.03), 0px 1px 4px 0px rgba(0, 0, 0, 0.03);
}

.lvd-container-form {
  padding: 20px;
  margin-bottom: 20px;
}

.lvd-dialog-title {
  padding: 60px 24px 24px 20px !important;
}

.lvd-dialog-text {
  max-width: 700px;
  margin: 0 auto;
  padding: 0 24px;
}

.lvd-dialog-list {
  padding-top: 20px;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  -webkit-padding-start: 0;
}

.lvd-dialog-list li {
  list-style: none;
  padding: 10px 0;
  border-bottom: 1px solid #d6dbe0;
}
.lvd-dialog-list li:last-child {
  border: 0;
}
.lvd-dialog-list li span {
  font-weight: 700;
}

.lvd-upload-button button {
  margin: 0 auto !important;
  display: block !important;
}
.lvd-upload-button button span {
  display: inline-flex !important;
  margin-bottom: 0;
  vertical-align: middle;
}

.lvd-upload-curs {
  padding: 24px 24px 0 24px;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  clear: left;
  color: #3f526c;
}

.lvd-upload-sau {
  padding: 10px 24px;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  clear: left;
  color: #3f526c;
}

.clear {
  clear: both;
}

.lvd-stepper {
  box-shadow: none !important;
  background: none !important;
}
.lvd-login-container {
  flex-wrap: wrap;
  margin: 0 auto;
  display: flex;
  height: 100%;
  min-height: 100%;
  justify-content: space-around;
}
.lvd-login-logo {
  text-align: center;
  padding: 250px 0 150px 0;
  flex: 1;
}
.lvd-login-form {
  padding: 80px 0px;
  flex: 1;
}
.lvd-login-footer {
  text-align: center;
  padding: 60px 30px;
  font-size: 14px;
  font-weight: 400;
  opacity: 0.5;
}
.lvd-login-footer img {
  display: block;
  margin: 10px auto;
}

.lvd-login-paper {
  max-width: 500px !important;
  min-height: 250px;
  border-radius: 5px !important;
  padding: 60px 30px 30px 30px;
  margin: 20px;
}

.lvd-text-spacer {
  display: inline-block;
  width: 10px;
}

.lvd-video-container {
  position: relative;
  margin: -20px -20px 20px -20px;
}

.lvd-video-item {
  box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.03),
    0px 1px 2px 0px rgba(0, 0, 0, 0.03), 0px 1px 4px 0px rgba(0, 0, 0, 0.03);
  margin-bottom: 20px;
  background: #ffffff;
}
.lvd-video-title {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
}
.lvd-video-item-right {
  width: 398px;
  float: right;
}
.lvd-video-item-right img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
  border-radius: 0 5px 5px 0;
}
.lvd-video-author {
  opacity: 0.6;
}
.lvd-video-toolbar {
  position: absolute;
  bottom: 15px;
  left: 24px;
  right: 0;
}
.lvd-video-date {
  position: absolute;
  right: 24px;
  bottom: 15px;
  opacity: 0.6;
}
.lvd-video-item-left {
  width: calc(100% - 428px);
  float: left;
  position: relative;
  padding: 40px 0 60px 24px;
  min-height: 150px;
  color: #3f526c;
}
.lvd-mobile-stepper {
  box-shadow: none !important;
  justify-content: center !important;
  padding: 20px 0 20px 0 !important;
}

.-odd {
  background: rgba(241, 242, 244, 0.6) !important;
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: rgba(241, 242, 244, 1) !important;
}

.lvd-stepper-left .MuiPaper-elevation0 {
  box-shadow: none;
  height: 100%;
}

.lvd-drawer .MuiPaper-root {
  background: #e1e2e5;
  box-shadow: 0px 2px 2px -1px rgba(169, 170, 172, 0.09),
    0px 1px 2px 0px rgba(169, 170, 172, 0.09),
    0px 1px 4px 0px rgba(169, 170, 172, 0.03);
}

.lvd-drawer .MuiDrawer-paperAnchorDockedLeft {
  border-right: 1px solid #dadbdf;
  border-bottom: 0;
}

.lvd-register {
  display: flex;
  /* height: 100%; */
}

form .lvd-form-text-delimiter:first-child {
  padding-top: 0;
}

.lvd-form-text-delimiter {
  padding-top: 30px;
}

.lvd-language-box {
}

.lvd-register-title {
  color: #3f526c;
  font-size: 32px;
  font-weight: 700;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.4;
}

.MuiInput-input span {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  background-color: transparent !important;
}

.lvd-validate-reject-buttons {
  padding-bottom: 24px;
}
